<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDevice"
                >添加模板</el-button
              >
            </el-form-item>
             <el-form-item label="" style="margin-left: 2vw">
              <el-input
                v-model="searchName"
                placeholder="请输入模板名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
         <el-button
          style="margin-left: 1vw"
          type="success"
          size="small"
          @click="search"
          >搜索</el-button
        >
        <el-button
          style="margin-left: 1vw; display: none"
          type="success"
          size="small"
          @click="update"
          >重置</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh"
    >
      <!-- <el-table-column prop="id" label="序号" align="center"></el-table-column> -->
      <el-table-column
        prop="templateName"
        label="模板名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sensorNameStr"
        label="传感器"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="coefficientStr"
        label="系数"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="diffValue"
        label="差值"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createUser"
        label="创建人"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="400">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="success" size="small" icon="el-icon-edit"
            ></el-button
          >
          <el-button @click="del(scope.row)" type="success" size="small" icon="el-icon-delete"
            ></el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加修改 -->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="68vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="10vw"
        >
          <el-form-item label="模板名称" prop="templateName">
            <el-input
              v-model="addForm.templateName"
              placeholder="请输入模板名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择传感器" prop="templateContent">
            <el-checkbox-group v-model="checkList" @change="mainChange">
              <!-- <el-checkbox
                v-for="item in sensorList"
                :key="item.id"
                :label="item"
                >{{ item.sensorName }}
                  <div style="margin-left: 2vw;">系数：<el-input type="number" v-model="item.coefficient" style="width: 7vw;"></el-input></div>
                  <div style="margin-left: 2vw;">差值：<el-input type="number" v-model="item.different"  style="width: 7vw;"></el-input></div>
              </el-checkbox> -->
              <el-checkbox
                v-for="item in sensorList"
                :key="item.id"
                :label="item.id"
                >{{ item.sensorName }}
                  <div style="margin-left: 2vw;">系数：<el-input type="number" v-model="checkData['coefficient' + item.id]" style="width: 7vw;"></el-input></div>
                  <div style="margin-left: 2vw;">差值：<el-input type="number" v-model="checkData['different' + item.id]"  style="width: 7vw;"></el-input></div>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <!-- <div
          style="
            width: 100%;
            height: 4vh;
            border-bottom: 1px solid #e3e3e3;
            font-size: 1.5vh;
            font-weight: bold;
          "
        >
          已选择
        </div>
        <div class="tableboxs">
          <el-table
            ref="multipleTable"
            :data="tableData2"
            tooltip-effect="dark"
            style="width: 100%; margin-top: 1vh"
          >
            <el-table-column
              prop="id"
              label="ID"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit"
              label="单位"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="coeffcient"
              label="系数"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  @click="up(scope.row)"
                  type="success"
                  size="mini"
                  class="btn"
                  >上移</el-button
                >
                <el-button
                  @click="down(scope.row)"
                  type="success"
                  size="small"
                  class="btn"
                  >下移</el-button
                >
                <el-button
                  @click="delSensor(scope.row)"
                  type="success"
                  size="small"
                  class="btn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block pagingBox">
            <el-pagination
              @size-change="handleSizeChanges"
              @current-change="handleCurrentChanges"
              :current-page="pages"
              :page-sizes="[1000, 2000]"
              :page-size="sizes"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totals"
            >
            </el-pagination>
          </div>
        </div> -->
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--传感器弹窗-->
    <!-- <el-dialog :visible.sync="sensorMessageBox" class="sensorBox">
      <el-button
        type="success"
        size="small"
        class="bttons"
        @click="reset()"
        style="margin-left: 1vw"
        >重置</el-button
      >
      <el-button type="success" size="small" class="bttons" @click="selects()"
        >选择</el-button
      >
      <div class="boxs">
        <el-table
          ref="multipleTable"
          :data="
            tableData3.filter(
              (data) =>
                !searchKey ||
                data.name.toLowerCase().includes(searchKey.toLowerCase())
            )
          "
          style="width: 100%; height: 58vh"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="ID" prop="id"> </el-table-column>
          <el-table-column label="名称" prop="name"> </el-table-column>
          <el-table-column label="单位" prop="unit"> </el-table-column>
          <el-table-column label="系数" prop="coeffcient"> </el-table-column>
          <el-table-column align="right" width="200">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="searchKey"
                size="mini"
                placeholder="输入关键字搜索"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  name: "templateManagement",
  data() {
    return {
      tableData: [],
      tableData2: [],
      tableData3: [],
      page: 1,
      size: 10,
      total: 0,
      pages: 1,
      sizes: 1000,
      totals: 0,
      loading: false,
      formTitle: "",
      messageBox: false,
      showFlag: false,
      radio: "1",
      ids: 0,
      searchName: "",
      addForm: { templates: [] },
      checkList: [],
      rules: {
        templateName: [
          {
            required: true,
            message: "设备名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
        templateContent: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator:  (rule, value, callback)=> {
              if (this.checkList == '') {
                callback(new Error("不可为空"));
              } else {
                callback();
              }
            }
          }
        ],
      },
      showData: [],
      formTitles: "",
      searchKey: "",
      sensorMessageBox: false,
      arr: [],
      indexList: [],
      sensorList: [],
      checkData: {}
    };
  },
  mounted() {
    this.getList();
    this.getSensorList();
    // this.getSensorList();
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/sensorTemp/page", {
        page: this.page,
        size: this.size,
        sensorName: this.searchName || '',
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    //确认提交
    // saveForm(formName) {
    //   let url = "sensorTemp/addOrUpdate";
    //   let flag;
    //   let id = this.ids;
    //   if (this.formTitle == "新增") {
    //     flag = "add";
    //   } else if (this.formTitle == "编辑") {
    //     flag = "edit";
    //   }
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.$post(url, {
    //         name: this.addForm.name,
    //         flag: flag,
    //         templates: this.indexList.toString(),
    //         id: id,
    //       }).then((res) => {
    //         if (res.data.state == "success") {
    //           this.selectSensor();
    //           this.$message.success("操作成功");
    //           this.messageBox = false;
    //           this.sensorMessageBox = false;
    //           this.getList();
    //           this.searchName = "";
    //           this.search();
    //         } else {
    //           this.$message.error(res.data.msg);
    //           this.messageBox = false;
    //         }
    //       });
    //     } else {
    //       //console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },

    saveForm(formName) {
      let check = this.checkList;
      check = [...new Set(check)];
      let checkId = this.checkList;
      let coeffic = [];
      let differe = [];
      this.checkList.forEach(a => {
        coeffic.push(this.checkData['coefficient' + a])
        differe.push(this.checkData['different' + a])
      })
      this.addForm.templateContent = checkId.join("|");
      this.addForm.coefficientStr = coeffic.join("|");
      this.addForm.diffValue = differe.join("|");

      // let id = this.ids;
      this.addForm.id = this.ids;
      this.addForm.createUser = window.localStorage.getItem('user')
      //console.log(this.addForm);
      let url = '/sensorTemp/addOrUpdate';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, this.addForm
          ).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }    
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          // //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.checkData = {};
      this.checkList = [];
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.tableData2 = [];
      // this.templates=[]
      this.sensorList.forEach(a=> {
        this.$set(this.checkData, 'coefficient' + a.id, 0.1);
        this.$set(this.checkData, 'different' + a.id, 0);
      })
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
      // this.getSensorList();
    },
    //编辑
    edit(item) {
      this.sensorList.forEach((a, A) => {
        this.$set(this.checkData, 'coefficient' + a.id, 0.1);
        this.$set(this.checkData, 'different' + a.id, 0);
      })
      this.checkList = [];
      //console.log(item);
      this.ids = item.id;
      let tem = item.templateContent.split('|');
      let coef = item.coefficientStr.split('|');
      let diff = item.diffValue.split('|');
      this.checkList = [];
      tem.forEach((a, A) => {
        this.checkList.push(Number(a));
        this.checkData['coefficient' + a] = coef[A];
        this.checkData['different' + a] = diff[A];
      })

      // for (let a = 0; a < tem.length; a++) {
        // this.checkList.push({
        //   createTime:item.createTime,
        //   createUser:item.createUser,
        //   coefficient: coef[a],
        //   different: diff[a],
        //   sensorName: diff[a],
        // })
        // this.checkList[a].coefficient = coef[a];
        // this.checkList[a].different = diff[a];
        // this.checkList[a].sensorName = tem[a];
      // }
      //console.log(this.checkList);
      // this.checkList = tem;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.showFlag = true;
      this.indexList = [];
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
      });
      // this.getEditSelSensors();
    },
    // 编辑时获取已选择传感器列表
    getEditSelSensors() {
      this.$get("/sensorTemplate/getTempSensorList", {
        page: this.pages,
        limit: this.sizes,
        id: this.ids,
      }).then((res) => {
        //console.log(this.pages);
        //console.log(this.sizes);
        if (res.data.state == "success") {
          // this.tableData2 = this.arr;
          this.tableData2 = res.data.datas;
          this.totals = res.data.data.count;
          var index = 1;
          this.tableData2.forEach((i) => {
            //console.log("i", i);
            i.index = index++;
          });
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取传感器列表
    getSensorList() {
      this.$get("/sensorManage/getSensorInfos", {
        page: 1,
        size: 1e3,
        sensorName: '',
      }).then((res) => {
        // //console.log(res);
        if (res.data.state == "success") {
          let newData = res.data.datas;
          for (let i = 0; i < newData.length; i++) {
              newData[i].coefficient = 0.1;
              newData[i].different = 0;
          }
          this.sensorList = newData;

          //console.log(this.sensorList );
        }
        
      });
    },
    mainChange(item) {
      //console.log(item);
    },

    // 选择传感器调用
    selectSensor() {
      this.title = "";
      this.sensorMessageBox = true;
    },
    // 传感器提交选择
    // selects() {
    //   this.getSensorList();
    //   this.sensorMessageBox = false;
    //   // //console.log(  this.$refs.multipleTable.selection);
    //   this.arr = this.$refs.multipleTable.selection;
    //   //console.log(this.arr);
    //   let sensorList = [];
    //   this.indexList = [];
    //   this.arr.forEach((x) => {
    //     //console.log("x--", x);
    //     sensorList.push(x.name);
    //     this.indexList.push(x.id);
    //   });
    //   this.addForm.templates = sensorList;
    // },
    // 重置
    reset() {
      //console.log("重置");
      this.searchKey = "";
      // this.getSensorList();
    },
    up(item) {
      var index = item.index - 1;
      if (item.index == 1) {
        //已经在顶部了，不能上移了
        return;
      }
      this.$get("/sensorTemplate/moveUp", {
        tempId: this.ids,
        index: index,
      }).then((res) => {
        //console.log(res);
        if (res.status == 200) {
          //console.log("上移成功");
          this.getEditSelSensors();
          this.getList();
        }
      });
    },
    down(item) {
      var index = item.index - 1;
      if (item.index >= this.tableData2.length) {
        //已经在最底部了，不能下移了
        return;
      }
      this.$get("/sensorTemplate/moveDown", {
        tempId: this.ids,
        index: index,
      }).then((res) => {
        if (res.status == 200) {
          //console.log("下移成功");
          this.getEditSelSensors();
          this.getList();
        }
      });
    },
    // 已选择传感器列表的删除
    delSensor(item) {
      var index = item.index - 1;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/sensorTemplate/delete", {
            tempId: this.ids,
            index: index,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getEditSelSensors();
              this.getList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    
    //最外层删除
    // del(item) {
    //   this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$post("/template/delTemplate", {
    //         id: item.id,
    //       }).then((res) => {
    //         if (res.data.state == "success") {
    //           this.$message.success("删除成功");
    //           this.getList();
    //           this.page = 1;
    //           this.update();
    //         } else {
    //           this.$message.info("删除失败");
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    del(item) {
      let id = item.id;
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/sensorTemp/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 第2页只有一条数据时删除时调用
    update() {
      this.page = 1;
      this.getList();
    },

    // 搜索
    search() {
      //console.log("搜索呀-----");
      this.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChanges(val) {
      this.sizes = val;
      this.getEditSelSensors();
    },
    handleCurrentChanges(val) {
      this.pages = val;
      this.getEditSelSensors();
    },
    // // 传感器添加删除
    // handleEdit(index, row) {
    //   //console.log(index, row);
    // },
    // handleDelete(index, row) {
    //   //console.log(index, row);
    // },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

/* #content /deep/ .el-table::-webkit-scrollbar {
  display: none;
} */

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .el-drawer {
  width: 36vw !important;
}

.icons {
  width: 30px !important;
}

.icons img {
  display: inline-block;
  width: 20%;
}

.btn,
.sensorBox /deep/ .el-button {
  width: 2.5vw !important;
  height: 3vh !important;
  line-height: 3vh !important;
  text-align: center !important;
  font-size: 1vh !important;
}
.tableboxs /deep/ .el-button,
.sensorBox /deep/ .el-button {
  padding: 0 !important;
}

.bttons {
  float: right;
}

.boxs {
  width: 100%;
  height: 60vh;
  overflow: hidden;
}
/*.tableBox{*/
/*  width: 100%;*/
/*  height: 70vh;*/
/*}*/

/deep/.el-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 47%;
}
/deep/ .el-checkbox .el-checkbox__label{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addAlaForm /deep/ .el-checkbox-group{
  height: 53vh;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
}

</style>
